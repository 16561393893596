import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Pusher, { Channel } from 'pusher-js';
import { Step } from 'react-joyride';
import { Dispatch } from 'redux';
import {
  AdvancedFilter,
  AltSignup,
  Appointment,
  AttendeeMessage,
  AttendeeMessagePopulated,
  Booth,
  BoothAccessUserType,
  BoothLikesCount,
  Challenge,
  EventInterface,
  EventPollQuestion,
  EventRegistration,
  EventTemplate,
  FadableElement,
  FavoriteAgendaEntry,
  FavoriteSlot,
  FavouriteBooth,
  LiveStream,
  ManagedToast,
  MeetingDisplayStatus,
  MeetingQueuePosition,
  Meetingroom,
  MessagingAttendee,
  MessagingConversation,
  Network,
  NetworkUser,
  NotificationInterface,
  OrgMembership,
  PageData,
  PhotoBooth,
  PresentationTicket,
  Privilege,
  PrivilegeRequestInterface,
  RegistrationField,
  RegistrationFieldFilter,
  Slot,
  SpeakerModalInterface,
  TeamMember,
  User,
  UserBan,
  UserMatch,
  VCardModalData,
} from '../../interfaces/interfaces';
import { AgendaEntry, ALL_COMPONENTS_INTERSECTION_TYPE, SpeakerEntry, SpeakersComponentInterface } from '../../interfaces/pagebuilder';
import {
  fetchAttendeeConversationsService,
  fetchMessagingAttendeesService,
  fetchOwnMessagingContactshares,
  fetchPresentationTickets,
  getAllEventTemplates,
  getAllTeamEvents,
  getAllUsers,
  getAuthData,
  getBoothAccessUsers,
  getBoothLikesCount,
  getBoothsBySlug,
  getChallenges,
  getEvent,
  GetEventBodyParams,
  getEventMeetings,
  getEventPages,
  getFavoriteSlots,
  getFavouriteBooths,
  getFilterCategories,
  getLiveStreams,
  getMatchingStatus,
  GetMatchingStatusBody,
  GetMeetingBodyParams,
  getMeetings,
  getMyUserMatchingsAll,
  getNetworks,
  getNotifications,
  getOrgMemberships,
  getOwnAnsweredPollsByEvent,
  getOwnEvents,
  getOwnFavoriteAgendaEntries,
  getOwnPrivilege,
  getOwnPrivilegeRequests,
  getPermanentBroadcast,
  getPhotobooths,
  getPollsPerEvent,
  getPollsPerMeeting,
  getQueuePositions,
  getRegistrationFields,
  getRegistrationsByUser,
  getSlots,
  getSpeakersForEvent,
  getUserBans,
} from '../../services/services';
import { CALLINGSTATE, SPEEDDATINGSTATE } from '../../utils/enums';

export interface CommonState {
  pusher: Pusher | null;
  pusherChannels: {
    eventChannel: Channel | null;
    matchingChannel: Channel | null;
    userEventChannel: Channel | null;
    meetingChannel: Channel | null;
    presenceChannel: Channel | null;
    reactionChannel: Channel | null;
    boothChannel: Channel | null;
  };
  meetingroomStatuses: Record<string, MeetingDisplayStatus>;
  canjoinStatuses: Record<string, boolean>;
  //TODO: Type
  queuePositions: MeetingQueuePosition[];
  selectedQueuePreview?: MeetingQueuePosition;
  connectcount?: number;
  modal: {
    open: boolean;
    sound: boolean;
    answer: any;
    response: boolean;
    question: string;
    closeButton: boolean;
    type?: string;
    backgroundImage?: string;
    customAnswers: any;
    timeoutSeconds?: number;
    _id?: string;
  };
  userbans: UserBan[] | undefined;
  userReportModal: {
    userName: string | null;
    messageId: string | null;
    userid: string | null;
  };
  broadcast: {
    _id: string;
    open: boolean;
    message: string;
    type: string;
  };
  event: EventInterface | null;
  /**
   * We move pages out of the event
   * to support filtering based on
   * registrationfields
   */
  pages: PageData[];
  /**
   * Wordaround for stage menu
   * to hide various buttons automatically
   */
  isHybrid: boolean;
  booths: Booth[];
  showPopup: {
    show: boolean;
    content: any;
    id: string;
    contentStyle: any;
  };
  meetingrooms?: Meetingroom[];
  wants_invite: boolean;
  user: User | null;
  // TODO: Add types
  eventRegistration: EventRegistration | null;
  eventRegistrationId: string | null;
  registrationFields: RegistrationField[];
  loadingRegistrationFields: boolean;
  advancedFilters: AdvancedFilter[];
  PDFModal: boolean;
  VCardModal: VCardModalData | null;
  slots: Slot[];
  favoriteSlots: FavoriteSlot[];
  axiosError: string | null;
  pagedata: PageData | null;
  isLoadingPageData: boolean;
  pagebuilder: {
    originalcomponents: ALL_COMPONENTS_INTERSECTION_TYPE[];
    currentcomponents: ALL_COMPONENTS_INTERSECTION_TYPE[];
    originalcolumncomponents: ALL_COMPONENTS_INTERSECTION_TYPE[];
    currentcolumncomponents: ALL_COMPONENTS_INTERSECTION_TYPE[];
    originalpagedata: PageData | null;
    currentpagedata: PageData | null;
    currentaltsignup: AltSignup | null;
    originalaltsignup: AltSignup | null;
    needToUpdate: boolean;
  };
  currentEditComponent: number;
  showVisibilitySettings: boolean;
  showGeneralSettingsOpened: boolean;
  fadableElements: {
    [key: string]: boolean;
  };
  templateEditMode: boolean;
  networks: Network[];
  livestreams: LiveStream[];
  selectedFilterValueId: string | null;
  photobooths: PhotoBooth[];
  challenges: Challenge[];
  reactPlayerPip: {
    isOpen: boolean;
    props: any;
    currentTime: number;
    id?: string;
  };
  appointments: Appointment[];
  approachingAppointment: Appointment | undefined;
  notifications: NotificationInterface[];
  privilege: Privilege | null | undefined;
  boothPrivilegedUsers: BoothAccessUserType[];
  appointmentReminder:
    | {
        timeout: ReturnType<typeof setTimeout>;
        appointment: Appointment;
      }
    | undefined;
  intercomId: string;
  eventPoll?: EventPollQuestion[];
  meetingPoll?: EventPollQuestion[];
  eventAnsweredPolls?: string[];
  meetingAnsweredPolls?: string[];
  answeredPolls?: any[];
  eventTemplates: EventTemplate[];
  loadingOwnEvents?: boolean;
  ownEvents: EventInterface[];
  attendeeMessaging:
    | {
        attendees?: MessagingAttendee[];
        conversations?: MessagingConversation[];
        messages?: AttendeeMessage[];
        pendingMessages?: AttendeeMessage[];
        attendeesCount?: number;
        reporters?: string[];
        isAttendeesFiltered?: boolean;
        boothOpens?: { boothid: string; attendee: MessagingAttendee }[] | undefined;
        isFetchingAttendees?: boolean;
      }
    | undefined;
  openConversation: MessagingConversation | undefined;
  openAttendeeMessaging: boolean;
  connectedNotification: boolean;
  favoriteAgendaEntries: FavoriteAgendaEntry[];
  teamUsers: TeamMember[];
  favouriteBooths: FavouriteBooth[];
  messagingOnlineFiltering: boolean;
  messagingSortByName: 'ascending' | 'descending' | undefined;
  messagingRegistrationFilters: RegistrationFieldFilter[];
  messagingShowHosts: boolean;
  // contains a callback that's allowed for registered users
  // used to show the signup modal
  postRegistrationAction: any;
  ownPrivilegeRequests: PrivilegeRequestInterface[];
  profileInfoUser: string | undefined;
  menuItemPrivileges: string[];
  showProfileModal: boolean;
  showProfileStatusModal: boolean;
  callState: {
    showModal: boolean;
    calleeId?: string; // Also used as condition to determine being the caller
    meetingId?: string; // Also used as condition to determine being the called one
    callerName?: string; // Only used to display the name of the one who is ringing
    calleeName?: string;
    eventId?: string;
    current: CALLINGSTATE;
    participants: OT.Connection[];
    session?: OT.Session;
    publisher?: OT.Publisher;
    nameOfPartner?: string;
    meeting?: Meetingroom;
    camActive: boolean;
    micActive: boolean;
  };
  callRingInterval?: ReturnType<typeof setInterval>;
  callRingAudio?: HTMLAudioElement;
  ownRegistrations: (Omit<EventRegistration, 'event'> & { event: EventInterface })[] | undefined;
  speeddating: {
    meetingId?: string;
    state: SPEEDDATINGSTATE;
  };
  matchHistory?: UserMatch[];
  allMatches?: UserMatch[];
  datetime_lastNewMessageNotification?: Date;
  lastNewMessageNotificationKey?: string;
  messagingContactshares: AttendeeMessagePopulated[];
  isTabUsedElsewhere: boolean;
  toasts: ManagedToast[];
  presentationTickets: PresentationTicket[];
  signInModal: boolean;
  signInModalMail?: string;
  lockedPBComponents?: string[];
  teamEvents: EventInterface[] | undefined;
  teamName?: string;
  orgMemberships: OrgMembership[];
  showLoginOverlay: boolean;
  isPBBoothsModal: boolean;
  isPBPresentationsModal: boolean;
  isPBEditSidebar: boolean;
  isPBAddModal: boolean;
  PBAddColumnComponent?: string;
  PBSelectedComponentIndex: number;
  PBComponentToEdit?: ALL_COMPONENTS_INTERSECTION_TYPE;
  algoliaSearchKey?: string;
  PBSpeakersModalComponent?: SpeakersComponentInterface;
  eventSpeakers?: SpeakerEntry[];
  PBAddSpeakerComponent?: SpeakersComponentInterface;
  showBoothOnboardModal?: boolean;
  isPBMatchingModal?: boolean;
  isMenuSidebarExtended?: boolean;
  isMobilePreview?: boolean;
  mobilePreviewWidth?: string;
  headerHeight?: number;
  joyRide: {
    running: boolean;
    steps: Step[];
  };
  speakerModal?: SpeakerModalInterface;
  agendaEntryModal?: AgendaEntry;
  eventDataLoadedId?: string;
  boothLikesCount: BoothLikesCount[];
  configView?: React.ReactNode;
  selectedPresentationLivestreamSlot?: Slot;
  hasShownConfirmCodeModal?: boolean;
}

const initialState: CommonState = {
  pusher: null,
  pusherChannels: {
    eventChannel: null,
    userEventChannel: null,
    meetingChannel: null,
    presenceChannel: null,
    reactionChannel: null,
    boothChannel: null,
    matchingChannel: null,
  },
  meetingroomStatuses: {},
  canjoinStatuses: {},
  queuePositions: [],
  selectedQueuePreview: undefined,
  connectcount: undefined,
  modal: {
    open: false,
    sound: true,
    answer: null,
    response: false,
    question: '',
    type: '',
    closeButton: false,
    customAnswers: null,
  },
  userbans: undefined,
  userReportModal: {
    userName: null,
    messageId: null,
    userid: null,
  },
  broadcast: {
    _id: '',
    open: false,
    message: '',
    type: 'snackbar',
  },
  event: null,
  pages: [],
  booths: [],
  isHybrid: false,
  showPopup: {
    show: false,
    content: undefined,
    id: '',
    contentStyle: undefined,
  },
  meetingrooms: undefined,
  wants_invite: false,
  user: null,
  eventRegistration: null,
  eventRegistrationId: null,
  registrationFields: [],
  loadingRegistrationFields: true,
  advancedFilters: [],
  PDFModal: false,
  VCardModal: null,
  slots: [],
  favoriteSlots: [],
  axiosError: null,
  pagedata: null,
  isLoadingPageData: true,
  pagebuilder: {
    originalcomponents: [],
    currentcomponents: [],
    originalcolumncomponents: [],
    currentcolumncomponents: [],
    originalpagedata: null,
    currentpagedata: null,
    currentaltsignup: null,
    originalaltsignup: null,
    needToUpdate: false,
  },
  currentEditComponent: -1,
  showVisibilitySettings: false,
  showGeneralSettingsOpened: false,
  fadableElements: {},
  templateEditMode: false,
  networks: [],
  livestreams: [],
  selectedFilterValueId: null,
  photobooths: [],
  challenges: [],
  reactPlayerPip: {
    isOpen: false,
    props: {},
    currentTime: 0,
    id: '',
  },
  approachingAppointment: undefined,
  notifications: [],
  appointments: [],
  privilege: undefined,
  boothPrivilegedUsers: [],
  appointmentReminder: undefined,
  intercomId: process.env.defaultIntercomId!,
  eventPoll: undefined,
  meetingPoll: undefined,
  answeredPolls: undefined,
  eventTemplates: [],
  ownEvents: [],
  loadingOwnEvents: undefined,
  attendeeMessaging: undefined,
  openConversation: undefined,
  openAttendeeMessaging: false,
  connectedNotification: true,
  favoriteAgendaEntries: [],
  teamUsers: [],
  favouriteBooths: [],
  messagingOnlineFiltering: false,
  messagingSortByName: undefined,
  messagingRegistrationFilters: [],
  messagingShowHosts: false,
  postRegistrationAction: undefined,
  ownPrivilegeRequests: [],
  profileInfoUser: undefined,
  menuItemPrivileges: [],
  showProfileModal: false,
  showProfileStatusModal: false,
  callState: {
    showModal: false,
    current: CALLINGSTATE.IDLE,
    participants: [],
    camActive: true,
    micActive: true,
  },
  ownRegistrations: undefined,
  speeddating: {
    state: SPEEDDATINGSTATE?.INACTIVE,
  },
  datetime_lastNewMessageNotification: undefined,
  lastNewMessageNotificationKey: undefined,
  messagingContactshares: [],
  isTabUsedElsewhere: false,
  toasts: [],
  presentationTickets: [],
  signInModal: false,
  lockedPBComponents: undefined,
  teamEvents: undefined,
  teamName: undefined,
  orgMemberships: [],
  isPBBoothsModal: false,
  isPBPresentationsModal: false,
  isPBEditSidebar: false,
  isPBAddModal: false,
  PBSelectedComponentIndex: -1,
  PBComponentToEdit: undefined,
  PBAddColumnComponent: undefined,
  algoliaSearchKey: undefined,
  PBSpeakersModalComponent: undefined,
  eventSpeakers: undefined,
  PBAddSpeakerComponent: undefined,
  showBoothOnboardModal: false,
  isPBMatchingModal: false,
  isMenuSidebarExtended: true,
  isMobilePreview: false,
  mobilePreviewWidth: undefined,
  headerHeight: 0,
  joyRide: {
    running: false,
    steps: [],
  },
  speakerModal: undefined,
  agendaEntryModal: undefined,
  showLoginOverlay: false,
  eventDataLoadedId: undefined,
  boothLikesCount: [],
  configView: undefined,
  selectedPresentationLivestreamSlot: undefined,
  hasShownConfirmCodeModal: false,
};

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    resetCommon: (state, action) => initialState,
    setIsHybrid: (state, action) => {
      state.isHybrid = action.payload;
    },
    setConfigView: (state, action) => {
      state.configView = action.payload;
    },
    setPusher: (state, action) => {
      state.pusher = action.payload;
    },
    setPusherEventChannel: (state, action) => {
      state.pusherChannels.eventChannel = action.payload;
    },
    setPusherUserEventChannel: (state, action) => {
      state.pusherChannels.userEventChannel = action.payload;
    },
    setPusherMeetingChannel: (state, action) => {
      state.pusherChannels.meetingChannel = action.payload;
    },
    setPusherPresenceChannel: (state, action) => {
      state.pusherChannels.presenceChannel = action.payload;
    },
    setPusherReactionChannel: (state, action) => {
      state.pusherChannels.reactionChannel = action.payload;
    },
    setPusherBoothChannel: (state, action) => {
      state.pusherChannels.boothChannel = action.payload;
    },
    setPusherMatchingChannel: (state, action) => {
      state.pusherChannels.matchingChannel = action.payload;
    },
    setMeetingroomStatus: (state, action: PayloadAction<{ meetingId: string; displayStatus: MeetingDisplayStatus; canJoin: boolean }>) => {
      const { meetingId, displayStatus, canJoin } = action.payload;
      state.meetingroomStatuses[meetingId] = displayStatus;
      if (canJoin !== undefined) state.canjoinStatuses[meetingId] = canJoin;
    },
    setConnectCount: (state, action) => {
      state.connectcount = action.payload;
    },
    setQueuePositions: (state, action) => {
      state.queuePositions = action.payload;
    },
    setSelectedQueuePreview: (state, action) => {
      state.selectedQueuePreview = action.payload;
    },
    setModal: (state, action) => {
      state.modal = action.payload;
    },
    setUserBans: (state, action) => {
      state.userbans = action.payload;
    },
    setUserReportModal: (state, action) => {
      state.userReportModal = action.payload;
    },
    setBroadcast: (state, action) => {
      state.broadcast = action.payload;
    },
    setWantsInvite: (state, action) => {
      state.wants_invite = action.payload;
    },
    setEvent: (state, action) => {
      state.event = action.payload;
    },
    setPages: (state, action) => {
      state.pages = action.payload;
    },
    setPagesWithoutRestricted: (state, action) => {
      state.pages = action.payload?.filter((page: any) => page.isRestrictOnRegistrationFields !== true);
    },
    setBooths: (state, action) => {
      state.booths = action.payload;
    },
    setPopup: (state, action) => {
      state.showPopup = action.payload;
    },
    setUser: (state, action: PayloadAction<User | null>) => {
      state.user = action.payload;
    },
    setEventRegistration: (state, action) => {
      state.eventRegistration = action.payload;
    },
    setEventRegistrationId: (state, action) => {
      state.eventRegistrationId = action.payload;
    },
    setEventRegistrationFields: (state, action) => {
      /**
       * Dirty workaround due to population issues
       * */
      state.eventRegistration!.registrationFields = action.payload;
    },
    setRegistrationFields: (state, action) => {
      state.registrationFields = action.payload;
      state.loadingRegistrationFields = false;
    },
    setAdvancedFilters: (state, action) => {
      state.advancedFilters = action.payload;
    },
    setPDFModal: (state, action) => {
      state.PDFModal = action.payload;
    },
    setVCardModal: (state, action) => {
      state.VCardModal = action.payload;
    },
    setMeetingrooms: (state, action: PayloadAction<Meetingroom[]>) => {
      state.meetingrooms = action.payload;
    },
    setSlots: (state, action: PayloadAction<Slot[]>) => {
      state.slots = action.payload;
    },
    setFavoriteSlots: (state, action: PayloadAction<FavoriteSlot[]>) => {
      state.favoriteSlots = action.payload;
    },
    setAxiosError: (state, action: PayloadAction<string | null>) => {
      state.axiosError = action.payload;
    },
    setPageData: (state, action: PayloadAction<PageData>) => {
      state.pagedata = action.payload;
    },
    setIsLoadingPageData: (state, action) => {
      state.isLoadingPageData = action.payload;
    },
    setPageBuilder: (state, action: PayloadAction<any>) => {
      state.pagebuilder = action.payload;
    },
    setPageBuilderCurrentPageData: (state, action: PayloadAction<any>) => {
      state.pagebuilder.currentpagedata = action.payload;
    },
    setFadableElement: (state, action: PayloadAction<FadableElement>) => {
      state.fadableElements[action.payload.name] = action.payload.visible;
    },
    setTemplateEditMode: (state, action: PayloadAction<boolean>) => {
      state.templateEditMode = action.payload;
    },
    setNetworks: (state, action: PayloadAction<Network[]>) => {
      state.networks = action.payload;
    },
    setLiveStreams: (state, action: PayloadAction<LiveStream[]>) => {
      state.livestreams = action.payload;
    },
    setPhotoBooths: (state, action: PayloadAction<PhotoBooth[]>) => {
      state.photobooths = action.payload;
    },
    setChallenges: (state, action: PayloadAction<Challenge[]>) => {
      state.challenges = action.payload;
    },
    setNotifications: (state, action: PayloadAction<NotificationInterface[]>) => {
      state.notifications = action.payload;
    },
    userJoinedMeetingRoom: (state, action: PayloadAction<{ meetingId: string; user: NetworkUser }>) => {
      const network = state.networks.find((meetingroom) => meetingroom._id === action.payload.meetingId);
      if (!network) return;
      const networkUser = network.users.find((nUser) => nUser._id === action.payload.user._id);
      if (networkUser) return;
      network.users.push(action.payload.user);
    },
    userLeftMeetingRoom: (state, action: PayloadAction<{ meetingId: string; userId: string }>) => {
      const network = state.networks.find((meetingroom) => meetingroom._id === action.payload.meetingId);
      if (!network) return;
      network.users = network.users.filter((u) => u._id !== action.payload.userId);
    },
    setSelectedFilterValueId: (state, action: PayloadAction<string | null>) => {
      state.selectedFilterValueId = action.payload;
    },
    setReactPlayerPip: (
      state,
      action: PayloadAction<{
        isOpen: boolean;
        props: any;
        currentTime: number;
        id?: string;
      }>
    ) => {
      state.reactPlayerPip = action.payload;
    },
    setEventPoll: (state, action: PayloadAction<any>) => {
      state.eventPoll = action.payload;
    },
    setMeetingPoll: (state, action: PayloadAction<any>) => {
      state.meetingPoll = action.payload;
    },
    setAnsweredPolls: (state, action: PayloadAction<any>) => {
      state.answeredPolls = action.payload;
    },
    setEventAnsweredPolls: (state, action: PayloadAction<any>) => {
      state.eventAnsweredPolls = action.payload;
    },
    setMeetingAnsweredPolls: (state, action: PayloadAction<any>) => {
      state.meetingAnsweredPolls = action.payload;
    },
    setApproachingAppointment: (state, action: PayloadAction<Appointment | undefined>) => {
      state.approachingAppointment = action.payload;
    },
    setUserPrivilege: (state, action: PayloadAction<Privilege | null | undefined>) => {
      state.privilege = action.payload;
    },
    setBoothPrivilegedUsers: (state, action: PayloadAction<BoothAccessUserType[]>) => {
      state.boothPrivilegedUsers = action.payload;
    },
    setAppointments: (state, action: PayloadAction<Appointment[]>) => {
      state.appointments = action.payload;
    },
    setConnectedNotification: (state, action) => {
      state.connectedNotification = action.payload;
    },
    setAppointmentRemineder: (
      state,
      action: PayloadAction<
        | {
            timeout: ReturnType<typeof setTimeout>;
            appointment: Appointment;
          }
        | undefined
      >
    ) => {
      state.appointmentReminder = action.payload;
    },
    setIntercom: (state, action: PayloadAction<string>) => {
      state.intercomId = action.payload;
    },
    setTeamUsers: (state, action: PayloadAction<TeamMember[]>) => {
      state.teamUsers = action.payload;
    },
    setEventTemplates: (state, action: PayloadAction<EventTemplate[]>) => {
      state.eventTemplates = action.payload;
    },
    setOwnEvents: (state, action: PayloadAction<EventInterface[]>) => {
      state.loadingOwnEvents = false;
      state.ownEvents = action.payload;
    },
    setLoadingOwnEvents: (state, action: PayloadAction<boolean>) => {
      state.loadingOwnEvents = action.payload;
    },
    setFavoriteAgendaEntries: (state, action: PayloadAction<FavoriteAgendaEntry[]>) => {
      state.favoriteAgendaEntries = action.payload;
    },
    setAttendeeMessaging: (state, action) => {
      state.attendeeMessaging = action.payload;
    },
    setAttendeeMessagingAttendees: (state, action) => {
      state.attendeeMessaging = {
        ...(state.attendeeMessaging ? state.attendeeMessaging : {}),
        attendees: action.payload.attendees || [],
        attendeesCount: action.payload.attendeeCount,
        reporters: action.payload.reporters,
      };
    },
    setAttendeeMessagingConversationsAndMessages: (state, action) => {
      state.attendeeMessaging = { ...state.attendeeMessaging, conversations: action.payload.conversations, messages: action.payload.messages };
    },
    setLoadingMessagingAttendees: (state, action) => {
      state.attendeeMessaging = {
        ...(state.attendeeMessaging ? state.attendeeMessaging : {}),
        isFetchingAttendees: action.payload,
      };
    },
    setMessagingOpenConversation: (state, action: PayloadAction<MessagingConversation | undefined>) => {
      state.openConversation = action.payload;
    },
    setAttendeeNewMessage: (state, action) => {
      if (!state.attendeeMessaging) return;
      const _messages = state.attendeeMessaging?.messages;
      const newMessages = _messages && _messages?.length > 0 ? [action.payload, ..._messages] : [action.payload];
      state.attendeeMessaging = { ...state.attendeeMessaging, messages: newMessages };
    },
    setPendingMessage: (state, action) => {
      if (!state.attendeeMessaging || !state.attendeeMessaging.messages) return;
      const { oldId, ...message } = action.payload;
      state.attendeeMessaging.messages = state.attendeeMessaging.messages.map((m) => (m._id === oldId ? message : m));
    },
    setErrorMessage: (state, action) => {
      if (!state.attendeeMessaging || !state.attendeeMessaging.messages) return;
      state.attendeeMessaging.messages = state.attendeeMessaging.messages.map((m) => (m._id === action.payload ? { ...m, isError: true } : m));
    },
    setOpenAttendeeMessaging: (state, action: PayloadAction<boolean>) => {
      state.openAttendeeMessaging = action.payload;
    },
    setFavouriteBooths: (state, action: PayloadAction<FavouriteBooth[]>) => {
      state.favouriteBooths = action.payload;
    },
    setMessagingOnlineFiltering: (state, action: PayloadAction<boolean>) => {
      state.messagingOnlineFiltering = action.payload;
    },
    setMessagingSortByName: (state, action: PayloadAction<'ascending' | 'descending' | undefined>) => {
      state.messagingSortByName = action.payload;
    },
    setMessagingRegistrationFilters: (state, action: PayloadAction<RegistrationFieldFilter[]>) => {
      state.messagingRegistrationFilters = action.payload;
    },
    setMessagingShowHosts: (state, action: PayloadAction<boolean>) => {
      state.messagingShowHosts = action.payload;
    },
    setPostRegistrationAction: (state, action) => {
      state.postRegistrationAction = action.payload;
    },
    setOwnPrivilegeRequests: (state, action: PayloadAction<PrivilegeRequestInterface[]>) => {
      state.ownPrivilegeRequests = action.payload;
    },
    setProfileInfoUser: (state, action: PayloadAction<string | undefined>) => {
      state.profileInfoUser = action.payload;
    },
    setMenuItemPrivileges: (state, action: PayloadAction<string[]>) => {
      state.menuItemPrivileges = action.payload;
    },
    setShowCallModal: (state, action) => {
      state.callState.showModal = action.payload;
    },
    setCalleeId: (state, action) => {
      state.callState.calleeId = action.payload;
    },
    setCallMeetingId: (state, action) => {
      state.callState.meetingId = action.payload;
    },
    setCallEventId: (state, action) => {
      state.callState.eventId = action.payload;
    },
    setCallerName: (state, action) => {
      state.callState.callerName = action.payload;
    },
    setCallState: (state, action) => {
      state.callState = action.payload;
    },
    setCallRingInterval: (state, action) => {
      state.callRingInterval = action.payload;
    },
    setCallRingAudio: (state, action) => {
      state.callRingAudio = action.payload;
    },
    setCallingState: (state, action) => {
      state.callState.current = action.payload;
    },
    setCallParticipants: (state, action) => {
      state.callState.participants = action.payload;
    },
    setShowProfileModal: (state, action) => {
      state.showProfileModal = action.payload;
    },
    setShowProfileStatusModal: (state, action) => {
      state.showProfileStatusModal = action.payload;
    },
    setCallSession: (state, action) => {
      state.callState.session = action.payload;
    },
    setCallPublisher: (state, action) => {
      state.callState.publisher = action.payload;
    },
    setCallNameOfPartner: (state, action) => {
      state.callState.nameOfPartner = action.payload;
    },
    setCallMeeting: (state, action) => {
      state.callState.meeting = action.payload;
    },
    setCallCamActive: (state, action) => {
      state.callState.camActive = action.payload;
    },
    setCallMicActive: (state, action) => {
      state.callState.micActive = action.payload;
    },
    setOwnRegistrations: (state, action) => {
      state.ownRegistrations = action.payload;
    },
    setSpeeddating: (state, action) => {
      state.speeddating = action.payload;
    },
    setSpeeddatingState: (state, action) => {
      state.speeddating.state = action.payload;
    },
    setMatchHistory: (state, action) => {
      state.matchHistory = action.payload;
    },
    setAllMatches: (state, action) => {
      state.allMatches = action.payload;
    },
    setDatetimeLastNewMessageNotification: (state, action) => {
      state.datetime_lastNewMessageNotification = action.payload;
    },
    setLastNewMessageNotificationKey: (state, action) => {
      state.lastNewMessageNotificationKey = action.payload;
    },
    setMessagingContactshares: (state, action) => {
      state.messagingContactshares = action.payload;
    },
    setIsTabUsedElsewhere: (state, action) => {
      state.isTabUsedElsewhere = action.payload;
    },
    setToasts: (state, action) => {
      state.toasts = action.payload;
    },
    setPresentationTickets: (state, action) => {
      state.presentationTickets = action.payload;
    },
    setSignInModal: (state, action) => {
      state.signInModal = action.payload;
    },
    setSignInModalMail: (state, action) => {
      state.signInModalMail = action.payload;
    },
    setLockedPBComponents: (state, action) => {
      state.lockedPBComponents = action.payload;
    },
    setTeamEvents: (state, action) => {
      state.teamEvents = action.payload;
    },
    setTeamName: (state, action) => {
      state.teamName = action.payload;
    },
    setOrgMemberships: (state, action) => {
      state.orgMemberships = action.payload;
    },
    setIsPBEditSidebar: (state, action) => {
      state.isPBEditSidebar = action.payload;
    },
    setPBSelectedComponentIndex: (state, action) => {
      state.PBSelectedComponentIndex = action.payload;
    },
    setPBComponentToEdit: (state, action) => {
      state.PBComponentToEdit = action.payload;
    },
    setBoothVideos: (state, action) => {
      if (!state.booths) return;
      state.booths = state.booths.map((b) => (b._id === action.payload.boothid ? { ...b, boothVideos: action.payload.boothVideos } : b));
    },
    setIsPBBoothsModal: (state, action) => {
      state.isPBBoothsModal = action.payload;
    },
    setIsPBPresentationsModal: (state, action) => {
      state.isPBPresentationsModal = action.payload;
    },
    setIsPBAddModal: (state, action) => {
      state.isPBAddModal = action.payload;
    },
    setPBAddColumnComponent: (state, action) => {
      state.PBAddColumnComponent = action.payload;
    },
    setAlgoliaSearchKey: (state, action) => {
      state.algoliaSearchKey = action.payload;
    },
    setPBSpeakersModalComponent: (state, action) => {
      state.PBSpeakersModalComponent = action.payload;
    },
    setEventSpeakers: (state, action) => {
      state.eventSpeakers = action.payload;
    },
    setPBAddSpeakerComponent: (state, action) => {
      state.PBAddSpeakerComponent = action.payload;
    },
    setIsPBMatchingModal: (state, action) => {
      state.isPBMatchingModal = action.payload;
    },
    setShowBoothOnboardModal: (state, action) => {
      state.showBoothOnboardModal = action.payload;
    },
    setIsMenuSideBarExtended: (state, action) => {
      state.isMenuSidebarExtended = action.payload;
    },
    setIsMobilePreview: (state, action) => {
      state.isMobilePreview = action.payload;
    },
    setMobilePreviewWidth: (state, action) => {
      state.mobilePreviewWidth = action.payload;
    },
    setHeaderHeight: (state, action) => {
      state.headerHeight = action.payload;
    },
    setJoyRide: (state, action) => {
      state.joyRide.running = action.payload.running;
      state.joyRide.steps = action.payload.steps ?? [];
    },
    setSpeakerModal: (state, action: PayloadAction<SpeakerModalInterface | undefined>) => {
      state.speakerModal = action.payload;
    },
    setAgendaEntryModal: (state, action: PayloadAction<AgendaEntry | undefined>) => {
      state.agendaEntryModal = action.payload;
    },
    setEventDataLoadedId: (state, action) => {
      state.eventDataLoadedId = action.payload;
    },
    setBoothLikesCount: (state, action: PayloadAction<BoothLikesCount[]>) => {
      state.boothLikesCount = action.payload;
    },
    setShowGeneralSettingsOpened: (state, action) => {
      state.showGeneralSettingsOpened = action.payload;
    },
    setCurrentEditComponent: (state, action) => {
      state.currentEditComponent = action.payload;
    },
    setShowVisibilitySettings: (state, action) => {
      state.showVisibilitySettings = action.payload;
    },
    setSelectedPresentationLivestreamSlot: (state, action: PayloadAction<Slot | undefined>) => {
      state.selectedPresentationLivestreamSlot = action.payload;
    },
    setHasShownConfirmCodeModal: (state, action) => {
      state.hasShownConfirmCodeModal = action.payload;
    },
    setIsStageChatLocked: (state, action) => {
      if (state.pagedata) state.pagedata.stageSettings.chat.isLocked = action.payload;
    },
  },
});

export const {
  resetCommon,
  setPusher,
  setQueuePositions,
  setSelectedQueuePreview,
  setPusherUserEventChannel,
  setPusherEventChannel,
  setPusherMeetingChannel,
  setPusherPresenceChannel,
  setPusherReactionChannel,
  setPusherMatchingChannel,
  setPusherBoothChannel,
  setMeetingroomStatus,
  setShowBoothOnboardModal,
  setConnectCount,
  setModal,
  setUserBans,
  setUserReportModal,
  setBroadcast,
  setWantsInvite,
  setEvent,
  setPages,
  setPagesWithoutRestricted,
  setBooths,
  setUser,
  setNetworks,
  setAdvancedFilters,
  setPDFModal,
  setVCardModal,
  setSlots,
  setFavoriteSlots,
  setMeetingrooms,
  setAxiosError,
  setPageData,
  setIsHybrid,
  setIsLoadingPageData,
  setPageBuilder,
  setPageBuilderCurrentPageData,
  setFadableElement,
  setTemplateEditMode,
  userJoinedMeetingRoom,
  userLeftMeetingRoom,
  setEventRegistration,
  setEventRegistrationId,
  setEventRegistrationFields,
  setRegistrationFields,
  setPopup,
  setLiveStreams,
  setSelectedFilterValueId,
  setPhotoBooths,
  setChallenges,
  setReactPlayerPip,
  setEventPoll,
  setMeetingPoll,
  setAnsweredPolls,
  setApproachingAppointment,
  setNotifications,
  setAppointments,
  setUserPrivilege,
  setBoothPrivilegedUsers,
  setAppointmentRemineder,
  setIntercom,
  setTeamUsers,
  setLastNewMessageNotificationKey,
  setEventTemplates,
  setOwnEvents,
  setLoadingOwnEvents,
  setAttendeeMessaging,
  setMessagingOpenConversation,
  setAttendeeNewMessage,
  setOpenAttendeeMessaging,
  setPendingMessage,
  setErrorMessage,
  setConnectedNotification,
  setFavoriteAgendaEntries,
  setFavouriteBooths,
  setMessagingOnlineFiltering,
  setMessagingSortByName,
  setMessagingRegistrationFilters,
  setMessagingShowHosts,
  setPostRegistrationAction,
  setOwnPrivilegeRequests,
  setProfileInfoUser,
  setMenuItemPrivileges,
  setEventAnsweredPolls,
  setMeetingAnsweredPolls,
  setShowCallModal,
  setCalleeId,
  setCallMeetingId,
  setCallEventId,
  setCallerName,
  setCallState,
  setCallRingInterval,
  setCallRingAudio,
  setCallingState,
  setCallParticipants,
  setShowProfileModal,
  setShowProfileStatusModal,
  setCallSession,
  setCallPublisher,
  setCallNameOfPartner,
  setCallMeeting,
  setCallCamActive,
  setCallMicActive,
  setOwnRegistrations,
  setSpeeddating,
  setSpeeddatingState,
  setMatchHistory,
  setAllMatches,
  setDatetimeLastNewMessageNotification,
  setMessagingContactshares,
  setIsTabUsedElsewhere,
  setToasts,
  setPresentationTickets,
  setSignInModal,
  setSignInModalMail,
  setLockedPBComponents,
  setTeamEvents,
  setTeamName,
  setAttendeeMessagingAttendees,
  setAttendeeMessagingConversationsAndMessages,
  setLoadingMessagingAttendees,
  setOrgMemberships,
  setIsPBEditSidebar,
  setPBSelectedComponentIndex,
  setPBComponentToEdit,
  setBoothVideos,
  setIsPBBoothsModal,
  setIsPBPresentationsModal,
  setIsPBAddModal,
  setPBAddColumnComponent,
  setAlgoliaSearchKey,
  setPBSpeakersModalComponent,
  setEventSpeakers,
  setPBAddSpeakerComponent,
  setIsPBMatchingModal,
  setIsMenuSideBarExtended,
  setIsMobilePreview,
  setMobilePreviewWidth,
  setHeaderHeight,
  setJoyRide,
  setSpeakerModal,
  setAgendaEntryModal,
  setEventDataLoadedId,
  setBoothLikesCount,
  setShowGeneralSettingsOpened,
  setCurrentEditComponent,
  setShowVisibilitySettings,
  setConfigView,
  setSelectedPresentationLivestreamSlot,
  setHasShownConfirmCodeModal,
  setIsStageChatLocked,
} = commonSlice.actions;

export const fetchAndSetEvent = (getEventBodyParams: GetEventBodyParams) => async (dispatch: Dispatch) => {
  const data = await getEvent(getEventBodyParams);
  dispatch(setEvent(data.data));
};

export const fetchAndSetQueuePositions = (eventId: string) => async (dispatch: Dispatch) => {
  const data = await getQueuePositions(eventId);
  dispatch(setQueuePositions(data.data));
};

export const fetchAndSetSlots = (filterEvent?: string) => async (dispatch: Dispatch) => {
  if (!filterEvent) return;
  const data = await getSlots(filterEvent);
  dispatch(setSlots(data.data));
};

export const fetchAndSetFavoriteSlots = (eventid?: string) => async (dispatch: Dispatch) => {
  const data = await getFavoriteSlots(eventid);
  dispatch(setFavoriteSlots(data.data));
};

export const fetchAndSetAllMatches = (registrationId: string) => async (dispatch: Dispatch) => {
  const data = await getMyUserMatchingsAll(registrationId);
  dispatch(setAllMatches(data));
};

// This function updates one specific slot after follow/unfollow
// It avoids checking all slots from db as follow/unfollow already returns the new favorited slot
export const localUpdateSlot = (slots: Slot[], newSlot: Slot, favorite?: FavoriteSlot) => async (dispatch: Dispatch) => {
  if (newSlot) {
    const slotsMutated = [...slots];
    const index = slotsMutated.findIndex((slot) => slot._id === newSlot._id);
    if (index !== -1) {
      slotsMutated[index] = newSlot;
      if (favorite) {
        const favoriteMutated: any = { ...favorite };
        favoriteMutated.slot = newSlot._id;
        slotsMutated[index] = favoriteMutated;
      }
    }
    dispatch(setSlots(slotsMutated));
  }
};

export const fetchAndSetMeetingrooms = (getMeetingBodyParams: GetMeetingBodyParams) => async (dispatch: Dispatch) => {
  const data = await getMeetings(getMeetingBodyParams);
  dispatch(setMeetingrooms(data.data));
};

export const fetchAndSetMatchingStatus = (getMatchingBody: GetMatchingStatusBody) => async (dispatch: Dispatch) => {
  const data = await getMatchingStatus(getMatchingBody);
  dispatch(setWantsInvite(data.data));
};

export const fetchAndSetUser = () => async (dispatch: Dispatch) => {
  const data = await getAuthData();
  if (data?.data?.user) {
    dispatch(setUser(data.data.user));
  }
};

export const fetchAndSetTeamUsers = (limit: number, org?: string, population?: { path: string }[]) => async (dispatch: Dispatch) => {
  const data = await getAllUsers(limit, org, population);
  dispatch(setTeamUsers(data.data));
};

export const fetchAndSetNetworks = (eventid: string) => async (dispatch: Dispatch) => {
  const data = await getNetworks(eventid);
  dispatch(setNetworks(data));
};

export const fetchAndSetRegistrationFields = (eventid: string) => async (dispatch: Dispatch) => {
  const { data } = await getRegistrationFields(eventid);
  dispatch(setRegistrationFields(data));
};

export const fetchAndSetBooths = (slug: string) => async (dispatch: Dispatch) => {
  const { data } = await getBoothsBySlug(slug);
  dispatch(setBooths(data));
};

export const fetchAndSetLivestreams = (event: string) => async (dispatch: Dispatch) => {
  const data = await getLiveStreams(event);
  dispatch(setLiveStreams(data));
};
export const fetchAndSetChallenges = (event: string) => async (dispatch: Dispatch) => {
  const data = await getChallenges(event);
  dispatch(setChallenges(data));
};

export const fetchAndSetPhotobooths = (event: string) => async (dispatch: Dispatch) => {
  const data = await getPhotobooths(event);
  dispatch(setPhotoBooths(data));
};

export const fetchAndSetAdvancedFilters = (eventid: string) => async (dispatch: Dispatch) => {
  const { data } = await getFilterCategories(eventid);
  dispatch(setAdvancedFilters(data));
};

export const fetchAndSetNotifications = (eventid: string) => async (dispatch: Dispatch) => {
  const { data } = await getNotifications(eventid);
  dispatch(setNotifications(data));
};

export const fetchAndSetUserPrivilege = (eventid: string) => async (dispatch: Dispatch) => {
  const { data } = await getOwnPrivilege(eventid);
  dispatch(setUserPrivilege(data ?? {}));
};

export const fetchAndSetBoothPrivilegedUsers = (boothId: string) => async (dispatch: Dispatch) => {
  const data = await getBoothAccessUsers(boothId);
  dispatch(setBoothPrivilegedUsers(data));
};

export const fetchAndSetEventPoll = (eventid: string) => async (dispatch: Dispatch) => {
  const data = await getPollsPerEvent(eventid);
  dispatch(setEventPoll(data));
};

export const fetchAndSetMeetingPoll = (meetingid: string) => async (dispatch: Dispatch) => {
  const data = await getPollsPerMeeting(meetingid);
  dispatch(setMeetingPoll(data));
};

export const fetchAndSetEventAnsweredPoll = (eventid: string) => async (dispatch: Dispatch) => {
  const data = await getPollsPerEvent(eventid);
  const _answeredEventPolls = data.filter((poll: EventPollQuestion) => poll.isAnswered).map((poll: EventPollQuestion) => poll._id);
  dispatch(setEventAnsweredPolls(_answeredEventPolls));
};

export const fetchAndSetMeetingAnsweredPoll = (meetingid: string) => async (dispatch: Dispatch) => {
  const data = await getPollsPerMeeting(meetingid);
  const _answeredMeetingPolls = data.filter((poll: EventPollQuestion) => poll.isAnswered).map((poll: EventPollQuestion) => poll._id);
  dispatch(setMeetingAnsweredPolls(_answeredMeetingPolls));
};

export const fetchAndSetAnsweredPolls = (eventid: string) => async (dispatch: Dispatch) => {
  const data = await getOwnAnsweredPollsByEvent(eventid);
  dispatch(setAnsweredPolls(data));
};

export const fetchAndSetEventTemplates = () => async (dispatch: Dispatch) => {
  const templates = await getAllEventTemplates();
  dispatch(setEventTemplates(templates));
};

export const fetchAndSetOwnEvents = () => async (dispatch: Dispatch) => {
  dispatch(setLoadingOwnEvents(true));
  const events = await getOwnEvents();
  dispatch(setOwnEvents(events));
  dispatch(setLoadingOwnEvents(false));
};

export const fetchAndSetFavoriteAgendaEntries = (eventid: string) => async (dispatch: Dispatch) => {
  const entries = await getOwnFavoriteAgendaEntries(eventid);
  dispatch(setFavoriteAgendaEntries(entries));
};

export const fetchAndSetAttendeeMessaging = (eventid: string) => async (dispatch: Dispatch) => {
  const { data: conversations } = await fetchAttendeeConversationsService(eventid);

  const sortedConversations = conversations.sort(
    (a, b) => new Date(b.messages[0].datetime_created).getTime() - new Date(a.messages[0].datetime_created).getTime()
  );

  const messages = sortedConversations.map((conversation) => conversation.messages).flat(1);

  dispatch(setAttendeeMessagingConversationsAndMessages({ conversations: sortedConversations, messages }));
};

export const fetchAndSetMessagingAttendees =
  (eventid: string, onlineFilter = false, showHosts = false, registrationFilters: RegistrationFieldFilter[] = [], sortByName?: 'ascending' | 'descending') =>
  async (dispatch: Dispatch) => {
    dispatch(setLoadingMessagingAttendees(true));
    try {
      const { data } = await fetchMessagingAttendeesService(eventid, undefined, undefined, onlineFilter, registrationFilters, showHosts, sortByName);
      dispatch(setAttendeeMessagingAttendees({ attendees: data.attendees, attendeeCount: data.count, reporters: data.reporters }));
    } catch (err) {
      console.error(err);
    }
    dispatch(setLoadingMessagingAttendees(false));
  };

export const fetchAndSetFavouriteBooths = (eventid?: string) => async (dispatch: Dispatch) => {
  const data = await getFavouriteBooths(eventid);
  dispatch(setFavouriteBooths(data?.data));
};

export const fetchAndSetBoothLikesCount = (eventId?: string) => async (dispatch: Dispatch) => {
  const data = await getBoothLikesCount(eventId);
  dispatch(setBoothLikesCount(data));
};

export const fetchAndSetPermanentBroadcast = (eventid: string) => async (dispatch: Dispatch) => {
  const data = await getPermanentBroadcast(eventid);
  dispatch(setBroadcast(data));
};

export const fetchAndSetUserBans = (eventid: string) => async (dispatch: Dispatch) => {
  const data = await getUserBans(eventid);
  dispatch(setUserBans(data));
};
export const fetchAndSetOwnEventPrivilegeRequests = (eventid: string) => async (dispatch: Dispatch) => {
  const data = await getOwnPrivilegeRequests(eventid);
  dispatch(setOwnPrivilegeRequests(data));
};

export const fetchAndSetOwnRegistrations = () => async (dispatch: Dispatch) => {
  const data = await getRegistrationsByUser();
  dispatch(setOwnRegistrations(data));
};

export const fetchAndSetMessagingContactshares = (eventid: string) => async (dispatch: Dispatch) => {
  const { data: messagingContactshares } = await fetchOwnMessagingContactshares(eventid);
  dispatch(setMessagingContactshares(messagingContactshares));
};

export const fetchAndSetPresentationTickets = (registration: string) => async (dispatch: Dispatch) => {
  const { data: presentationTickets } = await fetchPresentationTickets(registration);
  dispatch(setPresentationTickets(presentationTickets));
};

export const fetchAndSetTeamEvents = (teamName: string) => async (dispatch: Dispatch) => {
  const events = await getAllTeamEvents(teamName);
  dispatch(setTeamEvents(events));
};

export const fetchAndSetEventPages = (eventid: string) => async (dispatch: Dispatch) => {
  const { data: pages } = await getEventPages(eventid);
  dispatch(setPages(pages));
};

export const fetchAndSetOrgMemberships = () => async (dispatch: Dispatch) => {
  const events = await getOrgMemberships();
  dispatch(setOrgMemberships(events));
};

export const fetchAndSetEventSpeakers = (eventid: string) => async (dispatch: Dispatch) => {
  const speakers = await getSpeakersForEvent(eventid);
  dispatch(setEventSpeakers(speakers));
};

export const fetchAndSetEventMeetings = (eventid: string) => async (dispatch: Dispatch) => {
  const meetings = await getEventMeetings(eventid);
  dispatch(setMeetingrooms(meetings));
};

export default commonSlice.reducer;
